// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_tl";
export var lbContainerInner = "w_tm";
export var movingForwards = "w_tn";
export var movingForwardsOther = "w_tp";
export var movingBackwards = "w_tq";
export var movingBackwardsOther = "w_tr";
export var lbImage = "w_ts";
export var lbOtherImage = "w_tt";
export var prevButton = "w_tv";
export var nextButton = "w_tw";
export var closing = "w_tx";
export var appear = "w_ty";