// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_qw d_gv d_cs";
export var heroHeaderCenter = "p_gw d_gw d_cs d_dw";
export var heroHeaderRight = "p_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "p_qx d_gr d_cw";
export var heroParagraphCenter = "p_gs d_gs d_cw d_dw";
export var heroParagraphRight = "p_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "p_qy d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "p_qz d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "p_qB d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "p_qC d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "p_qD d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "p_qF r_qF";
export var heroExceptionNormal = "p_qG r_qG";
export var heroExceptionLarge = "p_qH r_qH";
export var Title1Small = "p_qJ r_qJ r_qR r_qS";
export var Title1Normal = "p_qK r_qK r_qR r_qT";
export var Title1Large = "p_qL r_qL r_qR r_qV";
export var BodySmall = "p_qM r_qM r_qR r_q8";
export var BodyNormal = "p_qN r_qN r_qR r_q9";
export var BodyLarge = "p_qP r_qP r_qR r_rb";